<template>
  <Field v-if="fetched" v-bind="$props" :emit-delay="500" v-model="metaValue" />
  <Spinner :loading="1" size="2em" class="info" v-else />
</template>

<script>
import Field from '../../mixins/Field'
import MetaField from './MetaFieldCommon'

const { value: omit, ...fieldProps } = Field.props

export default {
  name: 'MetaField',

  mixins: [MetaField],

  props: {
    ...fieldProps,
    type: {
      type: String,
      default: 'text'
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
