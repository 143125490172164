<template>
  <Page title="Global settings">
    <CardSection>
      <template #title>System settings</template>
      <CardList>
        <CardListField>
          <label>Udemy link</label>

          <MetaField :global="true" name="university_link" />
        </CardListField>
        <CardListField>
          <label>Meeting booking link for sales</label>

          <MetaField :global="true" name="sales_link" />
        </CardListField>
        <CardListField>
          <label>Billing support link</label>

          <MetaField :global="true" name="billing_link" />
        </CardListField>
        <CardListField>
          <label>Technical support link</label>

          <MetaField :global="true" name="support_link" />
        </CardListField>
      </CardList>
    </CardSection>
    <CardSection>
      <template #title>Subscription options</template>
      <CardList class="mb-4">
        <CardListField>
          <label>Early purchase coupon code</label>

          <MetaField :global="true" name="early_purchase_coupon_code" />
        </CardListField>
        <CardListField>
          <label>Early purchase coupon id</label>

          <MetaField :global="true" name="early_purchase_coupon_id" />
        </CardListField>
        <CardListField>
          <label>Early purchase coupon discount percentage ("20" for 20% etc)</label>

          <MetaField :global="true" name="early_purchase_coupon_discount_percentage" />
          <small class="text-muted suffix">%</small>
        </CardListField>
        <CardListField>
          <label
            >Early purchase coupon duration in months ("6" = 6 months, "12" = 12 months, ""/empty =
            forever)</label
          >

          <MetaField :global="true" name="early_purchase_coupon_duration" />
        </CardListField>
      </CardList>

      <CardList class="mb-4">
        <CardListField>
          <label>Free trial days</label>

          <MetaField :global="true" name="subscription_trial_days" />
        </CardListField>
      </CardList>

      <CardList v-for="option in subscriptionOptions" :key="option" class="mb-2">
        <CardListHeader> Subscription {{ option }} </CardListHeader>

        <CardListField>
          <label>Name</label>

          <MetaField :global="true" :name="`subscription_${option}_name`" />
        </CardListField>

        <CardListField>
          <label>Description</label>

          <MetaField type="textarea" :global="true" :name="`subscription_${option}_desc`" />
        </CardListField>

        <CardListField>
          <label>
            <Help>
              <template #before>List features</template>

              <p>
                Each new line becomes a new bullet point. Do not space items or add special
                characters like '-' or '•' etc.
              </p>
            </Help>
          </label>

          <MetaField type="textarea" :global="true" :name="`subscription_${option}_features`" />
        </CardListField>

        <CardListField>
          <label>
            <Help>
              <template #before>Stripe Monthly Price ID</template>
              <template #title
                >Stripe dashboard instructions to create a product and price</template
              >

              <p>
                Navigate to the
                <a href="https://dashboard.stripe.com/test/products/create">Create a product</a>
                page and create two products named <code>Basic</code> and <code>Premium</code>. Add
                a price for each product:
              </p>

              <ul>
                <li>
                  Basic
                  <ul>
                    <li>Pricing model: Graduated</li>
                    <li>First tier: 0-2,000 units, at 0 USD per unit, with a 15 USD flat fee</li>
                    <li>Second tier: 2,001+ units at .00100 USD per unit</li>
                    <li>Billing period: monthly</li>
                    <li><strong>Usage is metered</strong> checkbox selected</li>
                    <li>
                      <strong>Charge for metered usage by</strong> is set to
                      <strong>Sum of usage values during period</strong>
                    </li>
                  </ul>
                </li>
                <li>
                  Premium
                  <ul>
                    <li>Pricing model: Graduated</li>
                    <li>First tier: 0-10,000 units, at 0 USD per unit, with a 75 USD flat fee</li>
                    <li>Second tier: 10,0001+ units at .00075 USD per unit</li>
                    <li>Billing period: monthly</li>
                    <li><strong>Usage is metered</strong> checkbox selected</li>
                    <li>
                      <strong>Charge for metered usage by</strong> is set to
                      <strong>Sum of usage values during period</strong>
                    </li>
                  </ul>
                </li>
              </ul>

              <p>
                After you create the prices, record the price IDs so they can be used in subsequent
                steps. Each ID is displayed in the <strong>Pricing</strong> section of the product
                and should look similar to this: <code>price_G0FvDp6vZvdwRZ</code>.
              </p>
            </Help>
          </label>

          <MetaField :global="true" :name="`subscription_${option}_monthly_stripe_price_id`" />
        </CardListField>

        <CardListField>
          <label>Monthly price</label>

          <MetaField
            :global="true"
            format="currency"
            type="calculator"
            :name="`subscription_${option}_monthly_price`"
          />
        </CardListField>

        <CardListField>
          <label>
            <Help>
              <template #before>Stripe Yearly Price ID</template>
              <template #title
                >Stripe dashboard instructions to create a product and price</template
              >

              <p>
                Navigate to the
                <a href="https://dashboard.stripe.com/test/products/create">Create a product</a>
                page and create two products named <code>Basic</code> and <code>Premium</code>. Add
                a price for each product:
              </p>

              <ul>
                <li>
                  Basic
                  <ul>
                    <li>Pricing model: Graduated</li>
                    <li>First tier: 0-2,000 units, at 0 USD per unit, with a 15 USD flat fee</li>
                    <li>Second tier: 2,001+ units at .00100 USD per unit</li>
                    <li>Billing period: monthly</li>
                    <li><strong>Usage is metered</strong> checkbox selected</li>
                    <li>
                      <strong>Charge for metered usage by</strong> is set to
                      <strong>Sum of usage values during period</strong>
                    </li>
                  </ul>
                </li>
                <li>
                  Premium
                  <ul>
                    <li>Pricing model: Graduated</li>
                    <li>First tier: 0-10,000 units, at 0 USD per unit, with a 75 USD flat fee</li>
                    <li>Second tier: 10,0001+ units at .00075 USD per unit</li>
                    <li>Billing period: monthly</li>
                    <li><strong>Usage is metered</strong> checkbox selected</li>
                    <li>
                      <strong>Charge for metered usage by</strong> is set to
                      <strong>Sum of usage values during period</strong>
                    </li>
                  </ul>
                </li>
              </ul>

              <p>
                After you create the prices, record the price IDs so they can be used in subsequent
                steps. Each ID is displayed in the <strong>Pricing</strong> section of the product
                and should look similar to this: <code>price_G0FvDp6vZvdwRZ</code>.
              </p>
            </Help>
          </label>

          <MetaField :global="true" :name="`subscription_${option}_yearly_stripe_price_id`" />
        </CardListField>

        <CardListField>
          <label>Yearly price</label>

          <MetaField
            :global="true"
            format="currency"
            type="calculator"
            :name="`subscription_${option}_yearly_price`"
          />
        </CardListField>
      </CardList>
    </CardSection>
  </Page>
</template>

<script>
import MetaField from '../ui/fields/MetaField.vue'

export default {
  name: 'SuperSettings',
  components: { MetaField },
  data() {
    return {
      subscriptionOptions: ['A', 'B', 'C']
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
