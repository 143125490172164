export default {
  data() {
    return {
      metaValue: null,
      fetched: false
    }
  },
  watch: {
    metaValue(val, old) {
      this.$emit('input', this.metaValue)

      if (val !== old && this.fetched) {
        this.setValue(val)
      }
    }
  },
  props: {
    name: String,
    /**
     * Process the value received from keyvalue
     */
    process: {
      default: () =>
        function process(val) {
          return val
        }
    },
    global: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async getValue() {
      const recd = await this.$store.dispatch('Keyvalue/get', {
        key: this.name,
        global: this.global
      })
      const val = this.process.call(this, recd)
      this.metaValue = val
      await this.$nextTick()
      this.fetched = true
    },
    async setValue(value) {
      this.metaValue = value
      await this.$store.dispatch('Keyvalue/set', {
        key: this.name,
        value,
        global: this.global
      })
    }
  },
  mounted() {
    this.getValue()
  }
}
